import React, { useLayoutEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import classNames from "classnames/bind"
import Img from "gatsby-image"
import SEO from "../../components/seo"

// import wordmark_dark from '../../images/brand/wordmark_dark.svg';
import wordmark_orange from '../../images/brand/wordmark_orange.svg';

import style from "./@corporate.module.css"
import Anchors from "./anchors.js"
import Identity from "./identity.js"
import Clients from "./clients.js"
import Team from "./team.js"
import Contact from "./contact.js"
import Location from "./location.js"
import Footer from "./footer.js"
// import { Columns, Baseline } from "../../components/visual-aid.js"
// import client_mindlezz from "../../images/client/client-mindlezz.svg"

import {
  flashImage,
  customHookScrollEvent
  // pageSlowReveal,
  // highlightStoryLink,
  // highlightInterface,
  // customScrollEvent,
  // pullContent
} from "../../components/scroll-effects.js"

const cx = classNames.bind(style)

////


export default function Corporate() {

  const data = useStaticQuery(
    graphql`
      query {
        
        

        #
        corporate_landscape: file(relativePath: { eq: "work/corporate/corporate_landscape.jpg" }) {
          ...defaultImageTreatment
        }
        #
        corporate_portrait: file(relativePath: { eq: "work/corporate/corporate_portrait.jpg" }) {
          ...defaultImageTreatment
        }

        #
        client_AIG_1: file(relativePath: { eq: "work/corporate/client-aig-1.jpg" }) {
          ...defaultImageTreatment
        }
        #
        client_AIG_2: file(relativePath: { eq: "work/corporate/client-aig-2.jpg" }) {
          ...defaultImageTreatment
        }
        #
        client_pds_01: file(relativePath: { eq: "work/corporate/client_pds_01.jpg" }) {
          ...defaultImageTreatment
        }
        #
        client_pds_02: file(relativePath: { eq: "work/corporate/client_pds_02.jpg" }) {
          ...defaultImageTreatment
        }
        #
        client_pds_03: file(relativePath: { eq: "work/corporate/client_pds_03.jpg" }) {
          ...defaultImageTreatment
        }
        #
        client_pds_04: file(relativePath: { eq: "work/corporate/client_pds_04.jpg" }) {
          ...defaultImageTreatment
        }
        #
        client_pds_05: file(relativePath: { eq: "work/corporate/client_pds_05.jpg" }) {
          ...defaultImageTreatment
        }
        #
        client_aig_01: file(relativePath: { eq: "work/corporate/client_aig_01.jpg" }) {
          ...defaultImageTreatment
        }
        #
        client_aig_02: file(relativePath: { eq: "work/corporate/client_aig_02.jpg" }) {
          ...defaultImageTreatment
        }
        #
        client_aig_03: file(relativePath: { eq: "work/corporate/client_aig_03.jpg" }) {
          ...defaultImageTreatment
        }
        #
        client_aig_04: file(relativePath: { eq: "work/corporate/client_aig_04.jpg" }) {
          ...defaultImageTreatment
        }
        #
        client_aig_05: file(relativePath: { eq: "work/corporate/client_aig_05.jpg" }) {
          ...defaultImageTreatment
        }
        #
        client_eastbound_ggn_01: file(relativePath: { eq: "work/corporate/client_eastbound_ggn_01.jpg" }) {
          ...defaultImageTreatment
        }
        #
        client_eastbound_ggn_02: file(relativePath: { eq: "work/corporate/client_eastbound_ggn_02.jpg" }) {
          ...defaultImageTreatment
        }
        #
        client_eastbound_ggn_03: file(relativePath: { eq: "work/corporate/client_eastbound_ggn_03.jpg" }) {
          ...defaultImageTreatment
        }
        #
        client_eastbound_ggn_04: file(relativePath: { eq: "work/corporate/client_eastbound_ggn_04.jpg" }) {
          ...defaultImageTreatment
        }
        #
        client_spencer_stuart_01: file(relativePath: { eq: "work/corporate/client_spencer_stuart_01.jpg" }) {
          ...defaultImageTreatment
        }
        #
        client_spencer_stuart_02: file(relativePath: { eq: "work/corporate/client_spencer_stuart_02.jpg" }) {
          ...defaultImageTreatment
        }
        #
        client_spencer_stuart_03: file(relativePath: { eq: "work/corporate/client_spencer_stuart_03.jpg" }) {
          ...defaultImageTreatment
        }
        #
        client_spencer_stuart_04: file(relativePath: { eq: "work/corporate/client_spencer_stuart_04.jpg" }) {
          ...defaultImageTreatment
        }
        #
        #
        client_mazars_01: file(relativePath: { eq: "work/corporate/client_mazars_01.jpg" }) {
          ...defaultImageTreatment
        }
        #
        client_mazars_02: file(relativePath: { eq: "work/corporate/client_mazars_02.jpg" }) {
          ...defaultImageTreatment
        }
        #
        client_mazars_03: file(relativePath: { eq: "work/corporate/client_mazars_03.jpg" }) {
          ...defaultImageTreatment
        }
        #
        client_mazars_04: file(relativePath: { eq: "work/corporate/client_mazars_04.jpg" }) {
          ...defaultImageTreatment
        }
        #
        #
        client_keyword_india_01: file(relativePath: { eq: "work/corporate/client_keyword_india_01.jpg" }) {
          ...defaultImageTreatment
        }
        #
        client_keyword_india_02: file(relativePath: { eq: "work/corporate/client_keyword_india_02.jpg" }) {
          ...defaultImageTreatment
        }
        #
        client_keyword_india_03: file(relativePath: { eq: "work/corporate/client_keyword_india_03.jpg" }) {
          ...defaultImageTreatment
        }
        #
        client_keyword_india_04: file(relativePath: { eq: "work/corporate/client_keyword_india_04.jpg" }) {
          ...defaultImageTreatment
        }
        #
        #
        client_eb_discoveries_01: file(relativePath: { eq: "work/corporate/client_eb_discoveries_01.jpg" }) {
          ...defaultImageTreatment
        }
        #
        client_eb_discoveries_02: file(relativePath: { eq: "work/corporate/client_eb_discoveries_02.jpg" }) {
          ...defaultImageTreatment
        }
        #
        client_eb_discoveries_03: file(relativePath: { eq: "work/corporate/client_eb_discoveries_03.jpg" }) {
          ...defaultImageTreatment
        }
        #
        client_eb_discoveries_04: file(relativePath: { eq: "work/corporate/client_eb_discoveries_04.jpg" }) {
          ...defaultImageTreatment
        }
        #
        #
      }
    `
  )

  useLayoutEffect(() => {
    flashImage("#client_pds_01")
    flashImage("#client_pds_02")
    flashImage("#client_pds_03")
    flashImage("#client_pds_04")
    flashImage("#client_pds_05")
    //
    flashImage("#client_aig_01")
    flashImage("#client_aig_02")
    flashImage("#client_aig_03")
    flashImage("#client_aig_04")
    flashImage("#client_aig_05")
    //
    flashImage("#client_eastbound_ggn_01")
    flashImage("#client_eastbound_ggn_02")
    flashImage("#client_eastbound_ggn_03")
    flashImage("#client_eastbound_ggn_04")
    //
    flashImage("#client_spencer_stuart_01")
    flashImage("#client_spencer_stuart_02")
    flashImage("#client_spencer_stuart_03")
    flashImage("#client_spencer_stuart_04")
    //
    flashImage("#client_mazars_01")
    flashImage("#client_mazars_02")
    flashImage("#client_mazars_03")
    flashImage("#client_mazars_04")
    //
    flashImage("#client_keyword_india_01")
    flashImage("#client_keyword_india_02")
    flashImage("#client_keyword_india_03")
    flashImage("#client_keyword_india_04")
    //
    flashImage("#client_eb_discoveries_01")
    flashImage("#client_eb_discoveries_02")
    flashImage("#client_eb_discoveries_03")
    flashImage("#client_eb_discoveries_04")
    //
    customHookScrollEvent("#tagline", "#tagline", "tagline_expanded", .9)
    customHookScrollEvent("#taglineTop", "#taglineTop", "taglineTop_expanded", .5)
    // customHookScrollEvent("#taglineTop", "#navigationWrapper", "navigationWrapper_moved", .4)
  }, [])

  return (
    <>
      {/* <Columns />
      <Baseline /> */}

      <SEO
        title="Spaces For Life"
        keywords={[`UX`, `UX Strategy`, `Bangalore`, `UX Design`, `Consultant`]}
      />
      <div className={style.page}>
        <main className={style.main}>
          <div className={style.aboveFold}>
            <div className={style.topLine}></div>
            <div className={style.topImage}>
              <div className={cx(style.topImageAsset)}>
                <div className={style.matte}></div>
                <Img
                  className={cx(style.image, style.bannerLandscape)}
                  fluid={data.corporate_landscape.childImageSharp.fluid}
                  alt="corporate_landscape.jpg"
                />
                <Img
                  className={cx(style.image, style.bannerPortrait)}
                  fluid={data.corporate_portrait.childImageSharp.fluid}
                  alt="corporate_portrait.jpg"
                />
              </div>

            </div>
            <div className={style.topInfo}>
              <img className={style.wordmark} src={wordmark_orange} alt="wordmark.svg" />
              <div id="taglineTop" className={style.taglineTop}>
                spaces for growth
              </div>
            </div>
          </div>

          <div id="about-work" className={style.aboutWork}>
            <div className={style.header}>Corporate Interiors</div>
            <div className={style.aboutWorkText}>
              'Work is worship' is the key concept while we create work spaces for our esteemed clients. The designed environment has psychological impacts on the users. Spending the most valuable time of everyday life in office environment makes it very important from design perspective. SAMYAK believes that the aura of any office space should transform people into - Progressive, Powerful and Sensitive human beings. We claim to design – ‘Space for Growth’ for our clients. To achieve this goal in-depth research and analysis of clients work culture, business strategies & corporate branding is done. These values are embedded in the designed workplace which helps these companies retain employees, increase their productivity and finally achieve growth in business. We work with our clients as integral team and help them evolve their business, customise and transform their workplaces to align with the Company’s vision, values & budgets. Due to growing challenges in current business and work habitat we create spaces that are – Flexible, Adaptable, Sustainable, Intuitive and Innovative. It helps clients to confront with future challenges and maintain their consistent growth.
            </div>
          </div>

          <div id="work" className={style.work}>
            <div className={style.header}>Work</div>


            {/* PDS ****************************** */}
            <div className={style.project}>
              <div className={style.projectDescription}>
                <div className={style.projectDescriptionHighlight}>
                  Simply Natural
                </div>
                <div className={style.projectDescriptionText}>
                  <p>
                    PDS Multinational Group headquarters are in Hong Kong. PDS planned to shift their operations from Sohna road Gurugram office to new location in Udyog Vihar, phase-1 on 6th floor. It was good sized floor plate to design. Client asked to design the interior layout with rustic look and feel, reflecting with current trends in their industry. However, the layout needed to follow strictly – Vaastu in orientation and circulation.  <br />
                  </p>
                  <p>
                    We enjoyed doing the design as it was an opportunity to express rustic contemporary feel through a number of finishes. Various materials used – ropes (Jute and Nylon), hard wood, Laminate, veneer, metal MS frames, cement boards, rustic paint finishes, rustic floor tiling etc. The overall design is contemporary, straight forward, rustic yet modern satisfying the taste of client. The final execution achieved within the targeted low cost budget of per sqft carpet area.
                  </p>
                </div>
                <div className={style.projectData}>
                  <div className={style.projectName}>PDS Multinational</div>
                  <div className={style.projectLocation}>Gurugram, India</div>
                  <div className={style.projectIndustry}>Textile & Retail</div>
                  <div className={style.projectCompletion}>2017</div>
                  <div className={style.projectSize}>10500</div>
                </div>
              </div>
              <div className={style.projectImages}>
                <div className={cx(style.projectImageContainer, style.imageWide)}>
                  <div className={style.matte}></div>
                  <div id="client_pds_01" className={cx(style.image)}>
                    <Img fluid={data.client_pds_01.childImageSharp.fluid}
                      alt="client_pds_01.jpg"
                    />
                  </div>
                </div>
                <div className={cx(style.projectImageContainer, style.imageWide)}>
                  <div className={style.matte}></div>
                  <div id="client_pds_05" className={cx(style.image)}>
                    <Img fluid={data.client_pds_05.childImageSharp.fluid}
                      alt="client_pds_05.jpg"
                    />
                  </div>
                </div>
                <div className={cx(style.projectImageContainer, style.imageWide)}>
                  <div className={style.matte}></div>
                  <div id="client_pds_02" className={cx(style.image)}>
                    <Img fluid={data.client_pds_02.childImageSharp.fluid}
                      alt="client_pds_02.jpg"
                    />
                  </div>
                </div>
                <div className={cx(style.projectImageContainer, style.imageWide)}>
                  <div className={style.matte}></div>
                  <div id="client_pds_03" className={cx(style.image)}>
                    <Img fluid={data.client_pds_03.childImageSharp.fluid}
                      alt="client_pds_03.jpg"
                    />
                  </div>
                </div>
                <div className={cx(style.projectImageContainer, style.imageWide)}>
                  <div className={style.matte}></div>
                  <div id="client_pds_04" className={cx(style.image)}>
                    <Img fluid={data.client_pds_04.childImageSharp.fluid}
                      alt="client_pds_04.jpg"
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* PDS ****************************** */}

            {/* AIG ****************************** */}
            <div className={style.project}>
              <div className={style.projectDescription}>
                <div className={style.projectDescriptionHighlight}>
                  Amalgamated Modernity
                </div>
                <div className={style.projectDescriptionText}>
                  <p>
                    The design brief from the client was straight forward. They wished their new office environment to have a US office look, as the end clients are mostly US-based customers. However, it must complement the Indian value, though maintaining the modern persona. The layout was told to strictly follow – Vaastu principles. It was quite a challenge to mingle all these wishes in a single palette.
                  </p>
                  <p>
                    The final outcome was - efficient layout, purely Vaastu oriented yet accommodating 125 people lavishly. Use of glass partitions to achieve transparency as much as possible. The look and feel overall is intentionally created to high end. The materials used - Italian marble flooring, carpet, rugs and tiles etc in various areas. The furniture is a mix of imported and Indian brands. The speaking letters in the false ceiling is used extensively to spread the core company values throughout the office. The project is summed up in below lines -
                    ‘Variety and choice in overall work setting,
                    Activity-driven design, creating the right fit.
                  </p>
                </div>
                <div className={style.projectData}>
                  <div className={style.projectName}>AIG Business Solution Pvt. Ltd</div>
                  <div className={style.projectLocation}>Gurugram, India</div>
                  <div className={style.projectIndustry}>Professional Services</div>
                  <div className={style.projectCompletion}>2016</div>
                  <div className={style.projectSize}>12000</div>
                </div>
              </div>
              <div className={style.projectImages}>
                <div className={cx(style.projectImageContainer, style.imageWide)}>
                  <div className={style.matte}></div>
                  <div id="client_aig_01" className={cx(style.image)}>
                    <Img
                      fluid={data.client_aig_01.childImageSharp.fluid}
                      alt="client_aig_01.jpg"
                    />
                  </div>
                </div>
                <div className={cx(style.projectImageContainer, style.imageWide)}>
                  <div className={style.matte}></div>
                  <div id="client_aig_02" className={cx(style.image)}>
                    <Img
                      fluid={data.client_aig_02.childImageSharp.fluid}
                      alt="client_aig_02.jpg"
                    />
                  </div>
                </div>
                <div className={cx(style.projectImageContainer, style.imageWide)}>
                  <div className={style.matte}></div>
                  <div id="client_aig_03" className={cx(style.image)}>
                    <Img
                      fluid={data.client_aig_03.childImageSharp.fluid}
                      alt="client_aig_03.jpg"
                    />
                  </div>

                </div>
                <div className={cx(style.projectImageContainer, style.imageWide)}>
                  <div className={style.matte}></div>
                  <div id="client_aig_04" className={cx(style.image)}>
                    <Img fluid={data.client_aig_04.childImageSharp.fluid}
                      alt="client_aig_04.jpg"
                    />
                  </div>
                </div>
                <div className={cx(style.projectImageContainer, style.imageWide)}>
                  <div className={style.matte}></div>
                  <div id="client_aig_05" className={cx(style.image)}>
                    <Img
                      fluid={data.client_aig_05.childImageSharp.fluid}
                      alt="client_aig_05.jpg"
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* AIG ****************************** */}

            {/* EASTBOUND ****************************** */}
            <div className={style.project}>
              <div className={style.projectDescription}>
                <div className={style.projectDescriptionHighlight}>
                  Luxury in Warmth
                </div>
                <div className={style.projectDescriptionText}>
                  <p>
                    Eastbound, a tourism & travel company headquartered in Gurugram, Haryana has its offices in UAE, Bhutan, Nepal & Sri Lanka. With representative offices in the USA, UK, Italy, France, Russia, South America and Germany, EB has a well-established global alliance with some of the finest travel companies across the globe.
                  </p>
                  <p>
                    The design brief from client was to create a luxurious space with richness of hotel industry as their business is directly linked with hospitality sector. Following of Vaastu principles in interiors was a mandatory criteria on this project. The overall luxury has been created with local materials. The floor was done in marble tiles. Wooden cladding is done with veneer polish. Italian marble stone used for reception table and bar counter area. Furniture is proposed modular imported from Malaysia. Central office area open false ceiling is finished with white paint colour. Use of Green Bamboo is done deliberately as screening between office area and reception waiting lounge.
                  </p>
                </div>
                <div className={style.projectData}>
                  <div className={style.projectName}>Eastbound Group</div>
                  <div className={style.projectLocation}>Gurugram, India</div>
                  <div className={style.projectIndustry}>Tourism</div>
                  <div className={style.projectCompletion}>2015</div>
                  <div className={style.projectSize}>4500</div>
                </div>
              </div>
              <div className={style.projectImages}>
                <div className={cx(style.projectImageContainer, style.imageWide)}>
                  <div className={style.matte}></div>
                  <div id="client_eastbound_ggn_01" className={cx(style.image)}>
                    <Img fluid={data.client_eastbound_ggn_01.childImageSharp.fluid}
                      alt="client_eastbound_ggn_01.jpg"
                    />
                  </div>
                </div>
                <div className={cx(style.projectImageContainer, style.imageWide)}>
                  <div className={style.matte}></div>
                  <div id="client_eastbound_ggn_02" className={cx(style.image)}>
                    <Img fluid={data.client_eastbound_ggn_02.childImageSharp.fluid}
                      alt="client_eastbound_ggn_02.jpg"
                    />
                  </div>
                </div>
                <div className={cx(style.projectImageContainer, style.imageWide)}>
                  <div className={style.matte}></div>
                  <div id="client_eastbound_ggn_03" className={cx(style.image)}>
                    <Img fluid={data.client_eastbound_ggn_03.childImageSharp.fluid}
                      alt="client_eastbound_ggn_03.jpg"
                    />
                  </div>
                </div>
                <div className={cx(style.projectImageContainer, style.imageWide)}>
                  <div className={style.matte}></div>
                  <div id="client_eastbound_ggn_04" className={cx(style.image)}>
                    <Img fluid={data.client_eastbound_ggn_04.childImageSharp.fluid}
                      alt="client_eastbound_ggn_04.jpg"
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* EASTBOUND ****************************** */}

            {/* SPENCER ****************************** */}
            <div className={style.project}>
              <div className={style.projectDescription}>
                <div className={style.projectDescriptionHighlight}>
                  Elegant transformation
                </div>
                <div className={style.projectDescriptionText}>
                  <p>
                    Spencer Stuart is 60 years’ old company in the field of leadership consulting having 60 offices in 30 countries. In India there head office is in Mumbai. Their Gurugram office was newly built but couple of spaces like- Reception area, Main entry corridor, Dining sit outs etc. were left undone. Client approached us with request of refurbishment of these areas efficiently.
                  </p>
                  <p>
                    We tried to provide small design enhancements to uplift the existing look & feel to match with their international standards. Reception table designed as modern straight forward in veneer finish and white lacquered front. Reception backdrop wall proposed corian Jali to reflect the traditional Indian Jharoka designs. The main task was to create an elegant entry from narrow corridor. To break the linearity of corridor the ceiling was designed in stepped form. Flooring proposed with dark grey carpet. Continued windows proposed with stone sill and off-white blinds. Reception area waiting is proposed with stone texture (traventino) wall paper. Elegant and modern AV cabinet was provided in boardroom. Manifestation for office area designed in vertical linear pattern. Overall client appreciated the efforts and were very happy with final outcome.
                  </p>
                </div>
                <div className={style.projectData}>
                  <div className={style.projectName}>Spencer Stuart India Pvt. Ltd</div>
                  <div className={style.projectLocation}>Gurugram, India</div>
                  <div className={style.projectIndustry}>Corporate Consulting</div>
                  <div className={style.projectCompletion}>2011</div>
                  <div className={style.projectSize}>5000</div>
                </div>
              </div>
              <div className={style.projectImages}>
                <div className={cx(style.projectImageContainer, style.imageHalf)}>
                  <div className={style.matte}></div>
                  <div id="client_spencer_stuart_01" className={cx(style.image)}>
                    <Img
                      fluid={data.client_spencer_stuart_01.childImageSharp.fluid}
                      alt="client_spencer_stuart_o1.jpg"
                    />
                  </div>
                </div>
                <div className={cx(style.projectImageContainer, style.imageHalf)}>
                  <div className={style.matte}></div>
                  <div id="client_spencer_stuart_02" className={cx(style.image)}>
                    <Img
                      fluid={data.client_spencer_stuart_02.childImageSharp.fluid}
                      alt="client_spencer_stuart_02.jpg"
                    />
                  </div>
                </div>
                <div className={cx(style.projectImageContainer, style.imageHalf)}>
                  <div className={style.matte}></div>
                  <div id="client_spencer_stuart_03" className={cx(style.image)}>
                    <Img
                      fluid={data.client_spencer_stuart_03.childImageSharp.fluid}
                      alt="client_spencer_stuart_03.jpg"
                    />
                  </div>
                </div>
                <div className={cx(style.projectImageContainer, style.imageHalf)}>
                  <div className={style.matte}></div>
                  <div id="client_spencer_stuart_04" className={cx(style.image)}>
                    <Img
                      fluid={data.client_spencer_stuart_04.childImageSharp.fluid}
                      alt="client_spencer_stuart_04.jpg"
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* SPENCER ****************************** */}

            {/* MAZARS ****************************** */}
            <div className={style.project}>
              <div className={style.projectDescription}>
                <div className={style.projectDescriptionHighlight}>
                  Contemporary Reflections
                </div>
                <div className={style.projectDescriptionText}>
                  <p>
                    The Gurugram office of Mazars, a global audit, accounting, and consulting group was designed with 2 objectives. First, to showcase their international image and impress the potential clients visiting their office. Second, to get employees a flavour of their global company standards within the new built environment.
                  </p>
                  <p>
                    The final design successfully addressed both objectives. The overall Look N feel is well driven by the colours & imagery of the Mazars logo. Right from the reception desk, the elements of the corporate logo and colours are used innovatively. The international appeal has been obtained through local materials with their intelligent use.
                  </p>
                </div>
                <div className={style.projectData}>
                  <div className={style.projectName}>Mazars Advisory Private Limited</div>
                  <div className={style.projectLocation}>Gurugram, India</div>
                  <div className={style.projectIndustry}>Professional Services</div>
                  <div className={style.projectCompletion}>2016</div>
                  <div className={style.projectSize}>10000</div>
                </div>
              </div>
              <div className={style.projectImages}>
                <div className={cx(style.projectImageContainer, style.imageWide)}>
                  <div className={style.matte}></div>
                  <div id="client_mazars_01" className={cx(style.image)}>
                    <Img fluid={data.client_mazars_01.childImageSharp.fluid}
                      alt="client_mazars_01.jpg"
                    />
                  </div>
                </div>
                <div className={cx(style.projectImageContainer, style.imageWide)}>
                  <div className={style.matte}></div>
                  <div id="client_mazars_02" className={cx(style.image)}>
                    <Img fluid={data.client_mazars_02.childImageSharp.fluid}
                      alt="client_mazars_02.jpg"
                    />
                  </div>
                </div>
                <div className={cx(style.projectImageContainer, style.imageWide)}>
                  <div className={style.matte}></div>
                  <div id="client_mazars_03" className={cx(style.image)}>
                    <Img fluid={data.client_mazars_03.childImageSharp.fluid}
                      alt="client_mazars_03.jpg"
                    />
                  </div>
                </div>
                <div className={cx(style.projectImageContainer, style.imageWide)}>
                  <div className={style.matte}></div>
                  <div id="client_mazars_04" className={cx(style.image)}>
                    <Img fluid={data.client_mazars_04.childImageSharp.fluid}
                      alt="client_mazars_04.jpg"
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* MAZARS ****************************** */}

            {/* KEYWORD ****************************** */}
            <div className={style.project}>
              <div className={style.projectDescription}>
                <div className={style.projectDescriptionHighlight}>
                  Colourful Vitality
                </div>
                <div className={style.projectDescriptionText}>
                  <p>
                    Keyword India Network Pvt Ltd is an ISO Certified company working in the field of Web Branding & Web Marketing since 2012. Their new office space at The I-Thum, Noida is result of business expansion plan that company is looking desperately since last few months. In a small space of 1200 sqft, it was planned to accommodate 42 people, with a MD cabin and a conference room.
                  </p>
                  <p>
                    Initially the space provided by the builder had partition walls-glass & solid. These were effectively used and transformed to a totally fresh look. Lots of graphic, vinyl film, wall paper & manifestation used to achieve this modern colourful look desired by the client. Client has task of enduring creativity in their profession. They were looking towards us to embed that flavour of creativity within the small yet functional space. And this task performed by Samyak design team fantastically.
                  </p>
                </div>
                <div className={style.projectData}>
                  <div className={style.projectName}>Keyword India Network Pvt. Ltd.</div>
                  <div className={style.projectLocation}>Noida, UP, India.</div>
                  <div className={style.projectIndustry}>Digital Marketing</div>
                  <div className={style.projectCompletion}>2019</div>
                  <div className={style.projectSize}>1200</div>
                </div>
              </div>
              <div className={style.projectImages}>
                <div className={cx(style.projectImageContainer, style.imageWide)}>
                  <div className={style.matte}></div>
                  <div id="client_keyword_india_01" className={cx(style.image)}>
                    <Img fluid={data.client_keyword_india_01.childImageSharp.fluid}
                      alt="client_keyword_india_01.jpg"
                    />
                  </div>
                </div>
                <div className={cx(style.projectImageContainer, style.imageWide)}>
                  <div className={style.matte}></div>
                  <div id="client_keyword_india_02" className={cx(style.image)}>
                    <Img fluid={data.client_keyword_india_02.childImageSharp.fluid}
                      alt="client_keyword_india_02.jpg"
                    />
                  </div>
                </div>
                <div className={cx(style.projectImageContainer, style.imageWide)}>
                  <div className={style.matte}></div>
                  <div id="client_keyword_india_03" className={cx(style.image)}>
                    <Img fluid={data.client_keyword_india_03.childImageSharp.fluid}
                      alt="client_keyword_india_03.jpg"
                    />
                  </div>
                </div>
                <div className={cx(style.projectImageContainer, style.imageWide)}>
                  <div className={style.matte}></div>
                  <div id="client_keyword_india_04" className={cx(style.image)}>
                    <Img fluid={data.client_keyword_india_04.childImageSharp.fluid}
                      alt="client_keyword_india_04.jpg"
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* KEYWORD ****************************** */}

            {/* EASTBOUND DISCOVERIES ****************************** */}
            <div className={style.project}>
              <div className={style.projectDescription}>
                <div className={style.projectDescriptionHighlight}>
                  The Synergy in Freshness
                </div>
                <div className={style.projectDescriptionText}>
                  <p>
                    This is our second project with Eastbound Group. EB Discoveries is their new venture and established mainly to focus on the outbound traffic from Far East, Africa, Middle East, Central Europe, East Europe, Russia & CIS countries, Scandinavia and Latin America to India and the Sub-continent. The overall carpet area is mere 3200, but capable of housing a total staff strength of 52 including 10 senior executives, 5 cabins for Directors and a conference room with capacity of 10 pax. The new office is again mandated with Vaastu principles.
                  </p>
                  <p>
                    The overall space is created to reflect the youthfulness and energy with use of vibrant colours. The outcome is really refreshing. The look achieved is contemporary. The materials used are local with variety of finishes, from mirrors, to fabric and graphics on the walls. The intelligent implication of these material in design has actually maximized the space potential.
                  </p>
                </div>
                <div className={style.projectData}>
                  <div className={style.projectName}>Eastbound Group</div>
                  <div className={style.projectLocation}>Noida, UP, India.</div>
                  <div className={style.projectIndustry}>Tourism</div>
                  <div className={style.projectCompletion}>2018</div>
                  <div className={style.projectSize}>3200</div>
                </div>
              </div>
              <div className={style.projectImages}>
                <div className={cx(style.projectImageContainer, style.imageHalf)}>
                  <div className={style.matte}></div>
                  <div id="client_eb_discoveries_01" className={cx(style.image)}>
                    <Img fluid={data.client_eb_discoveries_01.childImageSharp.fluid}
                      alt="client_eb_discoveries_01.jpg"
                    />
                  </div>
                </div>
                <div className={cx(style.projectImageContainer, style.imageHalf)}>
                  <div className={style.matte}></div>
                  <div id="client_eb_discoveries_04" className={cx(style.image)}>
                    <Img fluid={data.client_eb_discoveries_04.childImageSharp.fluid}
                      alt="client_eb_discoveries_04.jpg"
                    />
                  </div>
                </div>
                <div className={cx(style.projectImageContainer, style.imageWide)}>
                  <div className={style.matte}></div>
                  <div id="client_eb_discoveries_02" className={cx(style.image)}>
                    <Img fluid={data.client_eb_discoveries_02.childImageSharp.fluid}
                      alt="client_eb_discoveries_02.jpg"
                    />
                  </div>
                </div>
                <div className={cx(style.projectImageContainer, style.imageWide)}>
                  <div className={style.matte}></div>
                  <div id="client_eb_discoveries_03" className={cx(style.image)}>
                    <Img fluid={data.client_eb_discoveries_03.childImageSharp.fluid}
                      alt="client_eb_discoveries_03.jpg"
                    />
                  </div>
                </div>
                {/* <div className={cx(style.projectImageContainer, style.imageHalf)}>
                  <div className={style.matte}></div>
                  <div id="client_eb_discoveries_04" className={cx(style.image)}>
                    <Img fluid={data.client_eb_discoveries_04.childImageSharp.fluid}
                      alt="client_eb_discoveries_04.jpg"
                    />
                  </div>
                </div> */}
              </div>
            </div>
            {/* EASTBOUND DISCOVERIES ****************************** */}


          </div>

          <div id="team" >
            < Team />
          </div>

          <div id="clients" >
            < Clients />
          </div>




          <div id="location" >
            < Location />
          </div>



          {/* <div id="contact" > */}
          < Footer />
          {/* </div> */}
          <div id="contact" >
            < Contact />
          </div>

          < Identity />


          < Anchors />

        </main>
      </div>

    </>
  )
}
